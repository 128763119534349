import React, { useEffect, useState, Fragment, useMemo } from 'react';
import dynamic from 'next/dynamic';
import { Dialog, Transition } from '@headlessui/react';
import { LineItem } from '@Types/cart/LineItem';
import { ShippingMethod } from '@Types/cart/ShippingMethod';
import { GrClose } from 'react-icons/gr';
import useSWR from 'swr';
import Loader from 'components/commercetools-ui/loader/Loader';
import Price from 'components/commercetools-ui/price';
import {
  APO_FPO_CITIES_MATCH,
  APO_FPO_STATES_MATCH,
  GA_CHECKOUT_TYPE_GUEST,
  GA_CHECKOUT_TYPE_REGULAR,
} from 'helpers/constants/aafes';
import useGATrackAddActions from 'helpers/hooks/gaEvents/useGATrackAddActions';
import { useFormat } from 'helpers/hooks/useFormat';
import { checkSessionExpiration } from 'helpers/utils/checkSessionExpiration';
import { getGuestShippingAddresses } from 'helpers/utils/getGuestShippingAddresses';
import { isParentBundleLineItem } from 'helpers/utils/ub-utils';
import { updateShippingDetails } from 'helpers/utils/updateShippingDetails';
import { fetchApiHub, revalidateOptions, useCart } from 'frontastic';
import { updateCustomerPreferences } from 'frontastic/actions/account';
import { updateGuestShippingAddress } from 'frontastic/actions/cart';
import { useAccount } from 'frontastic/provider';
const LoginModal = dynamic(() => import('./Login/login'));
import AddAddressModal from './addAddressModal';

interface ShippingProp {
  closeSippingModal?: () => void;
  lineItem?: LineItem;
  shippingMethods?: ShippingMethod[];
  seasonalMailTypeFlag?: string;
  itemShippingAddresses?: Array<any>;
  isShowAddressModal?: boolean;
  orderId?: string;
  isOrderShipping?: boolean;
  getAllCustomMessages?: any;
  communicationPref?: boolean;
}
interface ErrorMessages {
  shippingMethod?: string;
}
function ShippingModal({
  lineItem,
  closeSippingModal,
  shippingMethods,
  seasonalMailTypeFlag,
  orderId,
  isOrderShipping,
  getAllCustomMessages,
  communicationPref,
}: ShippingProp) {
  useEffect(() => {
    if (shippingMethods?.length && !selectedShippingMethod) {
      const selectedMethod = shippingMethods?.find(
        (method) => method.shippingMethodId == lineItem?.shippingDetails?.shippingInfo?.shippingMethodId,
      );
      setSelectedShippingMethod(
        selectedMethod ? selectedMethod.shippingMethodId : shippingMethods[0]['shippingMethodId'],
      );
    }
  }, [shippingMethods]);
  const cantShip = getAllCustomMessages?.cantShip;

  const allowEditAddressMsg = getAllCustomMessages?.allowEditAddressMsg;

  const ApoAddressError = () => {
    const queryParams = new URLSearchParams({
      messageKey: 'apoAddressError',
    });
    const { data } = useSWR(`/action/customObjects/getCustomMessage?${queryParams}`, fetchApiHub, revalidateOptions);

    return <span className="inline-flex text-sm text-red-500">{data}</span>;
  };
  const editShippingAddressNotAllowed = !Boolean(lineItem?.variant?.attributes?.allowEditAddress);

  const { data } = useSWR(
    `/action/customObjects/getCustomMessages?messages=allowEditAddressMsg|${
      encodeURIComponent(lineItem?.variant?.attributes?.vendorName) ?? ''
    }`,
    fetchApiHub,
    revalidateOptions,
  );

  const editingShippingAddressNotAllowedMessage =
    data?.allowEditAddressMsg && lineItem?.variant?.attributes?.vendorName ? data?.allowEditAddressMsg : '';

  const {
    updateLineItemShippingDetails,
    updateMarketplaceLineItemShippingDetails,
    data: cartData,
    updateBundleShippingAddress,
  } = useCart();
  const { account, loggedIn, updateSubscriptionShippingAddress, addresses: userAddresses } = useAccount();
  const guestUserAddresses = getGuestShippingAddresses(cartData);
  const addresses = loggedIn ? userAddresses : guestUserAddresses;
  const [selectedAddress, setSelectedAddress] = useState(
    lineItem?.addressKey ? lineItem?.addressKey : account?.addresses?.[0]?.addressId,
  );
  const [selectedShippingMethod, setSelectedShippingMethod] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const [showAddAddressModal, setShowAddAddressModal] = useState(false);
  const [nonShippableZips, setNonShippableZips] = useState([]);
  const [shippableZips, setShippableZips] = useState([]);
  const [nonShippableZipError, setNonShippableZipError] = useState(false);
  const isBundleItem = isParentBundleLineItem(lineItem);
  const [isApoFpoAddressSelected, setIsApoFpoAddressSelected] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsge, setErrorMsge] = useState('');
  const [noAddressSelectedError, setNoAddressSelectedError] = useState(false);
  const { trackAddShippingInfo } = useGATrackAddActions();
  const [addressAPOError, setAddressApoError] = useState(false);
  const { studioConfig } = useCart();
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>({});
  const [isDisableContinue, setIsDisableContinue] = useState(false);

  const checkMPItemsHasAPOFPO = (selectedAddress) => {
    if (lineItem?.isMarketplaceItem) {
      let selectedFullAddress = addresses?.find((address) => address?.addressId == selectedAddress);
      if (!selectedFullAddress) {
        selectedFullAddress = cartData?.itemShippingAddresses?.find((address) => address?.addressId == selectedAddress);
      }
      const isAPOFPOAddress =
        APO_FPO_STATES_MATCH?.includes(selectedFullAddress?.state) ||
        APO_FPO_CITIES_MATCH?.includes(selectedFullAddress?.city?.toLowerCase());

      const isProductAllowAPOFPO = lineItem?.variant?.attributes?.isAPOFPOAvailable;
      if (!isProductAllowAPOFPO && isAPOFPOAddress) {
        setError(true);
        setErrorMsge(getAllCustomMessages?.['pdp_shippingPolicy.APOFPOAvailable']);
        setIsDisableContinue(true);
      } else {
        setIsDisableContinue(false);
        setErrorMsge(null);
      }
    }
  };

  useEffect(() => {
    checkMPItemsHasAPOFPO(selectedAddress);
    if (lineItem?.variant?.attributes?.nonShippableZips?.length > 0) {
      setNonShippableZips(lineItem.variant.attributes.nonShippableZips.split(','));
    }
    if (lineItem?.variant?.attributes?.shippableZips?.length > 0) {
      setShippableZips(lineItem.variant.attributes.shippableZips.split(','));
    }
  }, [lineItem]);

  const checkApoFpo = () => {
    let isApoFpo = false;
    let selectedFullAddress = addresses?.find((address) => address.addressId == selectedAddress);
    if (!selectedFullAddress) {
      selectedFullAddress = cartData?.itemShippingAddresses?.find((address) => address.addressId == selectedAddress);
    }
    if (selectedFullAddress) {
      if (
        APO_FPO_STATES_MATCH.includes(selectedFullAddress.state) ||
        APO_FPO_CITIES_MATCH.find((apoCity) =>
          new RegExp('\\b' + apoCity + '\\b', 'i').test(selectedFullAddress.city.toLowerCase()),
        )
      ) {
        isApoFpo = true;
      }
    }
    setIsApoFpoAddressSelected(isApoFpo);
  };

  useEffect(() => {
    checkApoFpo();
  }, [selectedAddress, shippingMethods, addresses]);

  const addressDropdownSelection = (e) => {
    setNoAddressSelectedError(false);
    setError(false);
    setNonShippableZipError(false);
    setSelectedAddress(e.target.value);
    if (!communicationPref && !isOrderShipping) {
      checkMPItemsHasAPOFPO(e.target.value);
    }
  };

  const handleShippingMethodChange = (e) => {
    setErrorMessages((prevErrorMessages) => {
      const { shippingMethod, ...remainingErrors } = prevErrorMessages;
      return remainingErrors;
    });
    setError(false);
    setSelectedShippingMethod(e.target.value);
  };

  const logAddShippingInfo = (cartData, loggedIn: boolean): void => {
    const contentId = loggedIn ? GA_CHECKOUT_TYPE_REGULAR : GA_CHECKOUT_TYPE_GUEST;
    trackAddShippingInfo({
      cartData,
      contentId,
      shippingTier: shippingMethods?.[0]?.name,
    });
  };

  const setAddressToLineItem = async () => {
    if (isApoFpoAddressSelected && communicationPref) {
      setAddressApoError(true);
      return;
    }

    setAddressApoError(false);

    if (lineItem !== undefined && shippingMethods !== undefined) {
      if (!selectedAddress) {
        setNoAddressSelectedError(true);
        return;
      }
      if (
        lineItem?.addressKey === selectedAddress &&
        lineItem?.shippingDetails?.shippingInfo?.shippingMethodId === selectedShippingMethod
      ) {
        closeSippingModal();
        logAddShippingInfo(cartData, loggedIn);
        return;
      }
      if (
        selectedShippingMethod &&
        selectedAddress &&
        (lineItem?.addressKey !== selectedAddress ||
          lineItem?.shippingDetails?.shippingInfo?.shippingMethodId !== selectedShippingMethod)
      ) {
        const fullSelectedAddress = addresses?.find((address: any) => address?.addressId == selectedAddress);
        if (!fullSelectedAddress) {
          setNoAddressSelectedError(true);
          return;
        }
        setShowLoader(true);
        {
          const res = !isOrderShipping
            ? isBundleItem
              ? await updateBundleShippingAddress(
                  lineItem?.lineItemId,
                  {
                    firstName: fullSelectedAddress?.firstName ? fullSelectedAddress?.firstName : '',
                    lastName: fullSelectedAddress?.lastName ? fullSelectedAddress?.lastName : '',
                    addressId: fullSelectedAddress?.addressId ? fullSelectedAddress?.addressId : '',
                    streetName: fullSelectedAddress?.streetName ? fullSelectedAddress?.streetName : '',
                    streetNumber: fullSelectedAddress?.streetNumber ? fullSelectedAddress?.streetNumber : '',
                    postalCode: fullSelectedAddress?.postalCode ? fullSelectedAddress?.postalCode : '',
                    city: fullSelectedAddress?.city ? fullSelectedAddress?.city : '',
                    country: fullSelectedAddress?.country ? fullSelectedAddress?.country : 'US',
                    state: fullSelectedAddress?.state ? fullSelectedAddress?.state : '',
                    phone: fullSelectedAddress?.phone ? fullSelectedAddress?.phone : '',
                  },
                  selectedShippingMethod,
                )
              : loggedIn
              ? await updateShippingDetails(
                  lineItem,
                  fullSelectedAddress,
                  selectedShippingMethod,
                  updateMarketplaceLineItemShippingDetails,
                  updateLineItemShippingDetails,
                )
              : await updateGuestShippingAddress(
                  lineItem.lineItemId,
                  {
                    firstName: fullSelectedAddress?.firstName ? fullSelectedAddress?.firstName : '',
                    lastName: fullSelectedAddress?.lastName ? fullSelectedAddress?.lastName : '',
                    addressId: fullSelectedAddress?.addressId ? fullSelectedAddress?.addressId : '',
                    streetName: fullSelectedAddress?.streetName ? fullSelectedAddress?.streetName : '',
                    streetNumber: fullSelectedAddress?.streetNumber ? fullSelectedAddress?.streetNumber : '',
                    postalCode: fullSelectedAddress?.postalCode ? fullSelectedAddress?.postalCode : '',
                    city: fullSelectedAddress?.city ? fullSelectedAddress?.city : '',
                    country: fullSelectedAddress?.country ? fullSelectedAddress?.country : 'US',
                    state: fullSelectedAddress?.state ? fullSelectedAddress?.state : '',
                    phone: fullSelectedAddress?.phone ? fullSelectedAddress?.phone : '',
                  },
                  selectedShippingMethod,
                )
            : await updateSubscriptionShippingAddress({
                orderId: orderId,
                shippingAddress: {
                  firstName: fullSelectedAddress?.firstName ? fullSelectedAddress?.firstName : '',
                  lastName: fullSelectedAddress?.lastName ? fullSelectedAddress?.lastName : '',
                  addressId: fullSelectedAddress?.addressId ? fullSelectedAddress?.addressId : '',
                  streetName: fullSelectedAddress?.streetName ? fullSelectedAddress?.streetName : '',
                  streetNumber: fullSelectedAddress?.streetNumber ? fullSelectedAddress?.streetNumber : '',
                  postalCode: fullSelectedAddress?.postalCode ? fullSelectedAddress?.postalCode : '',
                  city: fullSelectedAddress?.city ? fullSelectedAddress?.city : '',
                  country: fullSelectedAddress?.country ? fullSelectedAddress?.country : 'US',
                  state: fullSelectedAddress?.state ? fullSelectedAddress?.state : '',
                  phone: fullSelectedAddress?.phone ? fullSelectedAddress?.phone : '',
                },
                shippingMethodId: selectedShippingMethod,
              });
          setShowLoader(false);
          if (res?.errorCode) {
            setError(true);
            if (res?.errorType === 'shippingMethod') {
              setErrorMessages((prevErrorMessages) => ({
                ...prevErrorMessages,
                shippingMethod: res?.body,
              }));
            } else {
              setErrorMsge(res?.body);
            }
          } else {
            closeSippingModal();
            logAddShippingInfo(cartData, loggedIn);
          }
        }
      }
    } else {
      const fullSelectedAddress = loggedIn
        ? account?.addresses?.find((address: any) => address?.addressId == selectedAddress)
        : cartData?.itemShippingAddresses.find((address: any) => address?.addressId == selectedAddress);
      updateCustomerPreferences(
        'seasonalAdByMailSubscriptionType',
        seasonalMailTypeFlag,
        'addressForSeasonalAd',
        fullSelectedAddress.addressId,
      );
      closeSippingModal();
      logAddShippingInfo(cartData, loggedIn);
    }
    trackAddShippingInfo({ cartData, account });
  };

  const getformattedAddress = (address) => {
    let formattedAddress = '';
    if (address.additionalAddressInfo) {
      formattedAddress = formattedAddress + address.additionalAddressInfo + ' - ';
    }
    if (address.streetName) {
      formattedAddress = formattedAddress + address.streetName + ', ';
    }
    if (address.streetNumber) {
      formattedAddress = formattedAddress + address.streetNumber + ', ';
    }
    if (address.city) {
      formattedAddress = formattedAddress + address.city + ', ';
    }
    if (address.state) {
      formattedAddress = formattedAddress + address.state + ' ';
    }
    if (address.postalCode) {
      formattedAddress = formattedAddress + address.postalCode;
    }
    return formattedAddress;
  };

  const afterSavingAddress = (data) => {
    setSelectedAddress(data.addresses[data.addresses.length - 1]['addressId']);
    setShowAddAddressModal(false);
  };

  const withoutSavingAddress = async (address) => {
    setShowLoader(true);
    {
      !isOrderShipping
        ? isBundleItem
          ? updateBundleShippingAddress(
              lineItem?.lineItemId,
              {
                firstName: address.firstName ? address.firstName : '',
                lastName: address.lastName ? address.lastName : '',
                streetName: address.streetName ? address.streetName : '',
                streetNumber: address.streetNumber ? address.streetNumber : '',
                postalCode: address.postalCode ? address.postalCode : '',
                city: address.city ? address.city : '',
                country: address.country ? address.country : 'US',
                state: address.state ? address.state : '',
                phone: address.phone ? address.phone : '',
              },
              selectedShippingMethod,
            )
              .then(() => {
                setShowAddAddressModal(false);
                closeSippingModal();
                logAddShippingInfo(cartData, loggedIn);
              })
              .catch((err) => console.log(err))
              .finally(() => setShowLoader(false))
          : loggedIn
          ? await updateShippingDetails(
              lineItem,
              address,
              selectedShippingMethod,
              updateMarketplaceLineItemShippingDetails,
              updateLineItemShippingDetails,
            )
              .then(() => {
                setShowAddAddressModal(false);
                closeSippingModal();
                logAddShippingInfo(cartData, loggedIn);
              })
              .catch((err) => console.log(err))
              .finally(() => setShowLoader(false))
          : updateGuestShippingAddress(
              lineItem.lineItemId,
              {
                firstName: address.firstName ? address.firstName : '',
                lastName: address.lastName ? address.lastName : '',
                streetName: address.streetName ? address.streetName : '',
                streetNumber: address.streetNumber ? address.streetNumber : '',
                postalCode: address.postalCode ? address.postalCode : '',
                city: address.city ? address.city : '',
                country: address.country ? address.country : 'US',
                state: address.state ? address.state : '',
                phone: address?.phone ? address?.phone : '',
              },
              selectedShippingMethod,
            )
              .then(() => {
                setShowAddAddressModal(false);
                closeSippingModal();
                logAddShippingInfo(cartData, loggedIn);
              })
              .catch((err) => console.log(err))
              .finally(() => setShowLoader(false))
        : updateSubscriptionShippingAddress({
            orderId: orderId,
            shippingAddress: {
              firstName: address.firstName ? address.firstName : '',
              lastName: address.lastName ? address.lastName : '',
              streetName: address.streetName ? address.streetName : '',
              streetNumber: address.streetNumber ? address.streetNumber : '',
              addressId: address?.addressId ? address?.addressId : '',
              postalCode: address.postalCode ? address.postalCode : '',
              city: address.city ? address.city : '',
              country: address.country ? address.country : 'US',
              state: address.state ? address.state : '',
              phone: address.phone ? address.phone : '',
            },
            shippingMethodId: selectedShippingMethod,
          })
            .then(() => {
              setShowAddAddressModal(false);
              closeSippingModal();
              logAddShippingInfo(cartData, loggedIn);
            })
            .catch((err) => console.log(err))
            .finally(() => setShowLoader(false));
    }
  };

  const [loginModal, setLoginModal] = useState(false);
  const [isRememberMeSessionExpired, setRememberMeSessionExpired] = useState(false);
  function closeLoginModal() {
    setLoginModal(false);
  }
  const openAddAddressModal = () => {
    const expirationDetails = checkSessionExpiration(account?.lastActivity, studioConfig?.sessionAuthExpirationMins);

    if (expirationDetails?.checkedKeepMeSignedIn) {
      setRememberMeSessionExpired(expirationDetails?.isUserSessionExpired);
      if (expirationDetails?.isUserSessionExpired) {
        setLoginModal(true);
      } else {
        setShowAddAddressModal(true);
      }
    } else {
      setShowAddAddressModal(true);
    }
  };
  return (
    <>
      <Transition appear show={true} as={Fragment}>
        <Dialog as="div" className={`relative ${loginModal ? 'z-[20]' : 'z-[99]'}`} onClose={closeSippingModal}>
          <Transition.Child
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="bg-black fixed inset-0 bg-opacity-25" />
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <section
                  className="absolute inset-0"
                  onClick={closeSippingModal}
                  role="dialog"
                  aria-labelledby="shippingModalTitle"
                >
                  <section
                    className="absolute top-1/2 left-1/2 max-h-[90vh] w-[90%] -translate-x-1/2 -translate-y-1/2 overflow-y-auto rounded bg-white px-[30px] pt-8 pb-4 dark:bg-primary-200 md:mt-9 md:w-[600px] lg:mt-3"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <section className="absolute top-3 right-3"></section>
                    <section className="text-left">
                      {lineItem?.isMarketplaceItem && editShippingAddressNotAllowed ? (
                        <section>
                          <section className="mb-[21px] flex justify-between">
                            <h3 className="text-[18px] leading-[21.6px] text-gray-900">
                              {' '}
                              <div
                                aria-label={editingShippingAddressNotAllowedMessage}
                                dangerouslySetInnerHTML={{ __html: editingShippingAddressNotAllowedMessage }}
                              />
                            </h3>
                            <button
                              type="button"
                              onClick={closeSippingModal}
                              className="h-[14px] w-[14px]"
                              aria-label="Close Button for Shipping Modal"
                            >
                              <GrClose />
                            </button>
                          </section>
                        </section>
                      ) : (
                        <section>
                          <section>
                            <section>
                              <section>
                                <section className="mb-[21px] flex justify-between">
                                  <h3 className="text-[18px] font-bold leading-[21.6px] text-gray-900">
                                    {' '}
                                    {formatCheckoutMessage({
                                      id: 'chooseShippingDetails',
                                      defaultMessage: 'Choose Shipping Details',
                                    })}
                                  </h3>
                                  <button
                                    type="button"
                                    onClick={closeSippingModal}
                                    className="h-[14px] w-[14px]"
                                    aria-label="Close Button for Shipping Modal"
                                  >
                                    <GrClose />
                                  </button>
                                </section>
                                <section className="w-full">
                                  <p className="my-2 mb-[7px] text-affes-sm font-bold leading-[16.8px] text-gray-900">
                                    {' '}
                                    {formatCheckoutMessage({
                                      id: 'selectShippingAddress',
                                      defaultMessage: 'Select a shipping address',
                                    })}
                                  </p>
                                  <section className="mb-4">
                                    <select
                                      className={`select h-[36px] w-full rounded  border-gray-600 py-[6px] px-[10px] leading-[26px] text-gray-900 ${
                                        nonShippableZipError ? 'border-red-500' : ''
                                      }`}
                                      onChange={addressDropdownSelection}
                                      value={selectedAddress}
                                      aria-invalid={nonShippableZipError}
                                      aria-describedby={nonShippableZipError ? 'nonShippableError' : null}
                                    >
                                      <option value="" aria-label="Please select">
                                        {formatCheckoutMessage({ id: 'pleaseSelect', defaultMessage: 'Please select' })}
                                      </option>
                                      {addresses?.map((address) => (
                                        <option
                                          selected={selectedAddress == address.addressId}
                                          key={address.addressId}
                                          value={address.addressId}
                                        >
                                          {getformattedAddress(address)}
                                        </option>
                                      ))}
                                    </select>
                                    {addressAPOError && communicationPref && isApoFpoAddressSelected && (
                                      <ApoAddressError />
                                    )}
                                    {error && (
                                      <span id="nonShippableError" className="inline-flex text-sm text-red-500">
                                        {errorMsge}
                                      </span>
                                    )}
                                    {nonShippableZipError && (
                                      <span className="inline-flex text-sm text-red-500">{cantShip}</span>
                                    )}
                                    {noAddressSelectedError && (
                                      <span className="inline-flex text-sm text-red-500" aria-live="assertive">
                                        {formatCheckoutMessage({
                                          id: 'selectShipAddr',
                                          defaultMessage: 'Please select shipping address.',
                                        })}
                                      </span>
                                    )}
                                  </section>
                                </section>
                                <button
                                  className="mb-6 text-affes-sm leading-[16.8px] text-blue-900 md:mb-12"
                                  onClick={() => {
                                    openAddAddressModal();
                                  }}
                                >
                                  {formatCheckoutMessage({
                                    id: 'addShippingAddress',
                                    defaultMessage: 'Add New Shipping Address',
                                  })}
                                </button>

                                <section className="mb-5 md:mb-[38px]">
                                  {!communicationPref && (
                                    <p className="mb-4 text-affes-sm font-bold leading-[16.8px] text-gray-900">
                                      {' '}
                                      {formatCheckoutMessage({
                                        id: 'selectShippingMethod',
                                        defaultMessage: 'Select a Shipping Method',
                                      })}
                                    </p>
                                  )}
                                  <section className="flex-col">
                                    {shippingMethods?.length > 0 &&
                                      shippingMethods.map((method) => (
                                        <>
                                          {(!isApoFpoAddressSelected || method.isApoFpoEligible) && (
                                            <section key={method.shippingMethodId} className="mb-[18px]">
                                              <input
                                                type="radio"
                                                id={method.shippingMethodId}
                                                name="shipping_method"
                                                value={method.shippingMethodId}
                                                onChange={handleShippingMethodChange}
                                                checked={selectedShippingMethod == method.shippingMethodId}
                                              />
                                              <label
                                                htmlFor={method.shippingMethodId}
                                                className="ml-1 text-affes-sm leading-[20px] text-gray-900"
                                              >
                                                {`${method.name}${' ' + method.description}`}
                                                {method.rates?.[0]?.price?.hasOwnProperty('centAmount') && (
                                                  <>
                                                    {method.rates?.[0]?.price?.centAmount > 0 ? (
                                                      <>
                                                        <span> - </span>
                                                        <Price
                                                          price={method.rates?.[0]?.price}
                                                          className="inline"
                                                        ></Price>
                                                      </>
                                                    ) : (
                                                      <span></span>
                                                    )}
                                                  </>
                                                )}
                                              </label>
                                            </section>
                                          )}
                                        </>
                                      ))}
                                  </section>
                                  {errorMessages?.shippingMethod && (
                                    <span
                                      id="invalidShippingMethodError"
                                      className="font-lato mt-[10px] text-sm font-normal leading-none text-red-900"
                                    >
                                      {errorMessages?.shippingMethod}
                                    </span>
                                  )}
                                </section>
                                <section className="md:flex">
                                  <button
                                    className={`mb-4 block h-11 w-full rounded ${
                                      isDisableContinue ? 'bg-[#9bb1c1]' : 'bg-blue-900'
                                    } text-affes-xl font-bold leading-[19.2px] text-white md:mb-0 md:w-[136px]`}
                                    onClick={setAddressToLineItem}
                                    disabled={isDisableContinue}
                                  >
                                    {formatCommonMessage({ id: 'continue', defaultMessage: 'Continue' })}
                                  </button>
                                  <button
                                    className=" mt-2 block w-full  text-center text-affes-xl font-bold leading-[19.2px] text-blue-900 md:m-0 md:w-32"
                                    onClick={closeSippingModal}
                                  >
                                    {formatCommonMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
                                  </button>
                                </section>
                              </section>
                            </section>
                          </section>
                        </section>
                      )}
                    </section>
                  </section>
                  <section>{showLoader && <Loader></Loader>}</section>
                  {loginModal && (
                    <LoginModal
                      loginModal={loginModal}
                      closeModal={closeLoginModal}
                      closeAccountFlyout={closeLoginModal}
                      loginCallBack={() => {}}
                      isRememberMeSessionExpired={isRememberMeSessionExpired}
                    />
                  )}
                  {showAddAddressModal && (
                    <AddAddressModal
                      withoutSavingAddress={withoutSavingAddress}
                      afterSavingAddress={afterSavingAddress}
                      closeAddAddressModal={() => setShowAddAddressModal(false)}
                      parentLoaderState={showLoader}
                      alwaysSave={false}
                      defaultValues={null}
                      editAddress={false}
                      isCart={communicationPref ? !communicationPref : true}
                      restrictedZips={nonShippableZips}
                      shippableZips={shippableZips}
                      existingAddresses={addresses}
                    ></AddAddressModal>
                  )}
                </section>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default ShippingModal;
