import React, { useEffect, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import router from 'next/router';
import { LineItem } from '@Types/cart/LineItem';
import { AiOutlineTag } from 'react-icons/ai';
import { FiAlertTriangle } from 'react-icons/fi';
import { MdOutlineInfo } from 'react-icons/md';
import Loader from 'components/commercetools-ui/loader/Loader';
import {
  DONATION_ITEMS,
  PRODUCT_TYPES_WITH_NO_SHIPPING_OPTIONS,
  FRONT_SMALL,
  PRODUCT_TYPES_WITH_QUANTITY_RESTRICTION,
  APO_FPO_STATES_MATCH,
  APO_FPO_CITIES_MATCH,
  EGIFT_CUSTOM_PRODUCTS,
  GA_CHECKOUT_TYPE_GUEST,
  GA_CHECKOUT_TYPE_REGULAR,
} from 'helpers/constants/aafes';
import useGATrackAddActions from 'helpers/hooks/gaEvents/useGATrackAddActions';
import useGATrackRemoveAction from 'helpers/hooks/gaEvents/useGATrackRemoveAction';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { mapLocaleToMeaningfulFormat } from 'helpers/utils/i18n';
import { isPreOrderable } from 'helpers/utils/preOrderable';
import renderVariantPickers from 'helpers/utils/renderVariantPickers';
import { desktop } from 'helpers/utils/screensizes';
import SmoothScroller from 'helpers/utils/SmoothScroller';
import { isParentBundleLineItem } from 'helpers/utils/ub-utils';
import { useCart, useAccount } from 'frontastic';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import GiftWarning from '../../../icons/giftwarning';
import AddOn from '../../../images/add-on-logo.png';
const Dialog = dynamic(() => import('../Modals/dialog'));
const MaxLimit = dynamic(() => import('../Modals/maxLimitModal'));
const AgeRestricted = dynamic(() => import('../pdp/product-preview/ageRestricted'));
const PreOrder = dynamic(() => import('../pdp/product-preview/preOrder'));
const Price = dynamic(() => import('../price'));
const AdditionalServices = dynamic(() => import('./additionalServices'));
const GiftBox = dynamic(() => import('./giftBox'));
const IncludedFree = dynamic(() => import('./includedFree'));
const ItemPrice = dynamic(() => import('./itemPrice'));
const QuantityDropdown = dynamic(() => import('./QuantityDropdown'));
const ShippingOptions = dynamic(() => import('./shippingOptions'));
const SubscriptionServices = dynamic(() => import('./subscriptionServices'));

enum MARKET_PLACE_ITEM_ATTRIBUTES {
  VENDOR_NAME = 'vendorName',
  ORDER_ID = 'orderId',
  VENDOR_NAME_COUPON = 'vendorNameCoupon',
  SPECIFICATION = 'specification',
}

const CartItem = ({
  item,
  index,
  hideBottomBorder,
  itemShippingAddresses,
  removeAddonProducts,
  shippingAddresses,
  giftCardImg,
  itemQty,
  getAllCustomMessages,
  shippingSurcharge,
  activationFeeItem,
  itemStockAvailability,
  setShowRecipientDetailsErrMsg,
  data,
  lineItemsStock,
  defaultShippingAddressNotAvailable,
  projectConfiguration,
}) => {
  const [isDesktop, width] = useMediaQuery(desktop);
  const [newUpdatedQty, setNewUpdatedQty] = useState(0);
  const { loggedIn } = useAccount();
  const [showModal, setShowModal] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const { formatMessage } = useFormat({ name: 'cart' });
  const { formatMessage: formatMessageAccount } = useFormat({ name: 'account' });

  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const {
    data: cartListItems,
    removeItem,
    removeMarketplaceItem,
    updateItem,
    moveToSaveForLater: saveToLater,
    removeBundle,
    updateBundleQuantity,
    fetchDefaultPayment,
    reCalculatePayments,
  } = useCart();
  const addonMessage = getAllCustomMessages?.addOnInfoMessage;
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const giftCardMessage = getAllCustomMessages?.receiveAGiftCard;
  const [maxLimitModal, setMaxLimitModal] = useState(false);
  const [addonItemId, setAddonItemId] = useState();
  const bundleItems = item?.bundleItems;
  const isBundleItem = isParentBundleLineItem(item);
  const [maxLimitErrorMessage, setMaxLimitErrorMessage] = useState(null);
  const { trackGARemoveFromCart } = useGATrackRemoveAction();
  const { trackAddToCart } = useGATrackAddActions();
  const isGiftCards = PRODUCT_TYPES_WITH_NO_SHIPPING_OPTIONS?.includes(item?.variant?.attributes?.customProductType);
  const { account } = useAccount();
  const [errorMessage, setErrorMessage] = useState(null);
  const [popupVisible, setPopupVisible] = useState(false);
  const popupRef = useRef(null);
  const buttonRef = useRef(null);
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const contentId = loggedIn ? GA_CHECKOUT_TYPE_REGULAR : GA_CHECKOUT_TYPE_GUEST;

  const handleRemoveClick = async (item: LineItem) => {
    let result;
    if (isBundleItem) {
      result = await removeBundle(item?.lineItemId);
    } else if (item?.isMarketplaceItem) {
      result = await removeMarketplaceItem(item?.lineItemId);
    } else {
      result = await removeItem(item?.lineItemId, !loggedIn);
    }
    if (result?.errorCode !== 500) {
      trackGARemoveFromCart({
        product: item,
        type: contentId,
        quantity: newUpdatedQty,
        cart: result,
      });
    }
  };

  const moveSaveForLater = async (item) => {
    const res = await saveToLater(item).then((response: any) => {
      if (response.errorCode === 500) {
        setMaxLimitModal(true);
        setMaxLimitErrorMessage(response.body);
      }
    });
  };
  const editItemQuantity = (lineItemId: string, newQuantity: number) => {
    setIsLoad(true);
    setNewUpdatedQty(newQuantity);
    const promise = isBundleItem
      ? updateBundleQuantity(lineItemId, newQuantity).finally(() => setIsLoad(false))
      : updateItem(lineItemId, newQuantity, !loggedIn).then((response: any) => {
          if (response.errorCode === 500) {
            setMaxLimitModal(true);
            setMaxLimitErrorMessage(response.body);
          } else {
            const isCart = router?.asPath?.includes('/cart') ? true : false;
            if (item?.count > newQuantity) {
              trackGARemoveFromCart({
                product: item,
                type: contentId,
                quantity: newQuantity,
                cart: response,
              });
            } else {
              const trackCartObj = {
                product: item,
                type: contentId,
                variant: response,
                quantity: newQuantity,
                isCart: isCart,
              };
              trackAddToCart(trackCartObj);
            }
          }
          if (addonItemId && item?.attachments?.length) {
            updateItem(addonItemId, newQuantity).then(() => {
              setIsLoad(false);
            });
          } else {
            setIsLoad(false);
          }
        });
    return promise;
  };

  const handleBundleEditClick = () => {
    setIsLoad(true);
    router.push(item?._bundleEditUrl);
  };
  const btnRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (btnRef.current && !btnRef.current.contains(event.target)) {
        setShowRemove(false);
      }
    };
    const handleWindowClick = (event) => {
      handleClickOutside(event);
    };
    window.addEventListener('click', handleWindowClick);
    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  }, []);
  //  if (isLoad) {
  //    return <Loader />;
  //  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${month}/${day}/${year}`;
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
      setPopupVisible(false);
    }
  };

  const checkMPItemsHasAPOFPO = () => {
    if (item?.isMarketplaceItem) {
      const isAPOFPOAddress =
        APO_FPO_STATES_MATCH?.includes(item?.shippingAddress?.state) ||
        APO_FPO_CITIES_MATCH?.includes(item?.shippingAddress?.city?.toLowerCase());

      const isProductAllowAPOFPO = item?.variant?.attributes?.isAPOFPOAvailable;
      if (!isProductAllowAPOFPO && isAPOFPOAddress) {
        setErrorMessage(getAllCustomMessages?.['pdp_shippingPolicy.APOFPOAvailable']);
      } else {
        setErrorMessage(null);
      }
    }
  };
  const checkClosedStore = () => {
    if (
      item?.channel?.id &&
      item?.channel.isClickToBrick === false &&
      !EGIFT_CUSTOM_PRODUCTS?.includes(item?.variant?.attributes?.customProductType)
    ) {
      setErrorMessage(getAllCustomMessages?.['selectedStoreClosed']);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [itemDiscounts, setItemDiscounts] = useState([]);

  useEffect(() => {
    checkMPItemsHasAPOFPO();
    checkClosedStore();
  }, [item, getAllCustomMessages]);

  useEffect(() => {
    const uniqueDiscounts = item?.discounts?.reduce((acc, currentDiscount) => {
      const existingDiscount = acc.find((discount) => discount.discountId === currentDiscount.discountId);
      if (!existingDiscount) {
        return [...acc, currentDiscount];
      } else {
        return acc;
      }
    }, []);
    setItemDiscounts(uniqueDiscounts);
  }, [item]);

  const marketPlaceItemDetailsJSX = {
    [MARKET_PLACE_ITEM_ATTRIBUTES.VENDOR_NAME]: (
      <>
        {item?.variant?.attributes?.vendorName && (
          <p className="mb-1 flex items-center font-bold text-gray-900 md:border-none">
            <span>{item?.variant?.attributes?.vendorName}</span>
          </p>
        )}
      </>
    ),
    [MARKET_PLACE_ITEM_ATTRIBUTES.ORDER_ID]: (
      <>
        {item?.clientLineOrderId && (
          <p className=" mb-2 text-sm font-medium">
            {' '}
            <span>
              {formatMessageAccount({
                id: 'Supplier Order ID:',
                defaultMessage: 'Supplier Order ID: ',
              })}{' '}
              {item?.clientLineOrderId}
            </span>{' '}
          </p>
        )}
      </>
    ),
    [MARKET_PLACE_ITEM_ATTRIBUTES.VENDOR_NAME_COUPON]: (
      <>
        {item.clientLineCoupons && (
          <section className="border-gray min-h-[46px] items-center rounded border px-[15px] py-3">
            <div className="flex">
              <>
                <AiOutlineTag className="my-auto mx-1 text-gray-900" />{' '}
                <p>
                  {' '}
                  {item?.variant?.attributes?.vendorName} &nbsp;
                  {formatMessageAccount({
                    id: 'vendorName.coupon',
                    defaultMessage: `${item?.variant?.attributes?.vendorName ?? ''} Coupon`,
                  })}{' '}
                  {item.clientLineCoupons}
                </p>
              </>
            </div>
          </section>
        )}
      </>
    ),
    [MARKET_PLACE_ITEM_ATTRIBUTES.SPECIFICATION]: (
      <>
        {Array.isArray(item?.clientLineSpecifications) &&
          item?.clientLineSpecifications?.length > 0 &&
          item.clientLineSpecifications.map((spec, index) => {
            const [key, value] = spec.split(':');
            return (
              <p key={index} className="text-sm font-medium">
                <span>
                  {key}: {value}
                </span>
              </p>
            );
          })}
      </>
    ),
  };

  function getItemImageSrc(item) {
    if (item?.isMarketplaceItem) {
      return item?.clientLineImageURL || item?.variant?.images?.[0];
    }
    return item?.variant?.imageSet?.[FRONT_SMALL]?.src?.[0]?.url || item?.variant?.images?.[0];
  }

  return (
    <>
      {maxLimitModal && <MaxLimit closeModal={setMaxLimitModal} customMessage={maxLimitErrorMessage} />}
      <section className="mb-4 border border-gray-700 p-2 lg:mb-0 lg:border-0 lg:p-0">
        {isLoad && <Loader />}
        <section className={`py-3 ${hideBottomBorder ? '' : 'lg:border-b'}`}>
          {errorMessage !== null && (
            <section className="mb-3 flex min-h-[46px] w-full items-center rounded border border-red-400 bg-red-100 px-[15px] pt-[14px] pb-4 text-affes-sm text-red-700">
              <FiAlertTriangle className="mr-2" />
              {errorMessage}
            </section>
          )}

          {item?.isGiftLineItem && (
            <section className="mb-[27px]">
              <section className="flex h-[46px] items-center rounded border border-[#AB7712] bg-[#FFFAEA] px-[15px] pt-[14px] pb-4">
                <GiftWarning className="mr-[14.47px]" />
                <p className="text-affes-sm leading-[16.8px] text-gray-900">
                  {formatMessage({
                    id: 'gift.item.msg',
                    defaultMessage: `This item is the gift. If you remove the above product this will be removed from your cart.`,
                  })}
                </p>
              </section>
            </section>
          )}

          {item?.isMarketplaceItem && item?.itemExpiryDate && (
            <section className="mb-[27px]">
              {getAllCustomMessages?.expireVendorCIWarning?.length && (
                <section className="flex min-h-[46px] items-center rounded border border-[#AB7712] bg-[#FFFAEA] px-[15px] pt-[14px] pb-4">
                  <GiftWarning className="mr-[14.47px]" />
                  <p className="text-affes-sm leading-[16.8px] text-gray-900">
                    {getAllCustomMessages?.expireVendorCIWarning.replace(
                      /EXPIRY_DATE|VENDOR_NAME/gi,
                      (matched: any) => {
                        if (matched === 'EXPIRY_DATE') {
                          return formatDate(item?.itemExpiryDate);
                        }
                        if (matched === 'VENDOR_NAME') {
                          return item?.variant?.name;
                        }
                      },
                    )}
                  </p>
                </section>
              )}
            </section>
          )}

          {item?.variant?.attributes?.isAddon && (
            <section className="mb-3 rounded border border-[#AB7712] bg-[#FFFAEA] px-3 text-sm font-normal text-gray-900">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={AddOn.src} className="mr-2 inline" alt="Item Addon Image" />
                {getAllCustomMessages?.addOnVerbiage}
                <div className="details-popover hidden" id="set"></div>
                <div className="group relative">
                  <button
                    ref={buttonRef}
                    onClick={() => setPopupVisible(true)}
                    className="text-primary ml-2 mt-1 text-lg"
                  >
                    <MdOutlineInfo />
                  </button>

                  <div
                    ref={popupRef}
                    className={`text-black absolute -right-4 top-[35px] z-10 w-60 rounded-md text-sm opacity-100 transition-all duration-300 md:-right-52 ${
                      popupVisible ? '' : 'hidden'
                    }`}
                  >
                    <div className="relative">
                      <div className="relative rounded-md bg-white ">
                        <div className="rounded-md border border-solid bg-white shadow-[0_2px_4px_0_rgba(0,0,0,0.10)]">
                          <div className="relative z-[1] flex rounded-t-md border-b border-solid bg-[#EDEDED] p-2 text-base font-bold after:absolute after:-top-[1px] after:left-[10px] after:inline-block after:h-[1px] after:w-[23px] after:bg-white after:content-['']">
                            {formatProductMessage({ id: 'addOn.details', defaultMessage: 'Add On Details' })}
                          </div>
                          <div className="pb-4">
                            <div
                              className="popup-scroll aafes-orderList h-60 overflow-y-auto px-4 py-2"
                              dangerouslySetInnerHTML={{ __html: addonMessage }}
                            />
                          </div>
                        </div>
                        <div className="absolute right-[10px] -top-[16px] z-0 h-4 w-4 -translate-x-1/2 translate-y-1/2 rotate-[225deg] bg-white drop-shadow-md md:left-[23px] md:right-0"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          <section className="mb-3 flex gap-5">
            {/* image */}
            <section className="flex grow">
              <section className="productImage">
                <section
                  className="mx-auto mb-3 h-[75px] w-[75px] md:h-[105px] md:w-[105px]"
                  aria-label={`Product Image ${item?.name}`}
                  tabIndex={0}
                >
                  {item?.variant?.attributes?.customProductType === DONATION_ITEMS && !loggedIn ? (
                    <Link href={item?._guestUrl}>
                      <img
                        className="cursor-pointer"
                        src={item?.variant?.imageSet?.[FRONT_SMALL]?.src?.[0]?.url || item?.variant?.images?.[0]}
                        alt="Item Variant"
                      />
                    </Link>
                  ) : (
                    <Link
                      href={
                        (!loggedIn
                          ? item?._guestUrl
                          : isBundleItem
                          ? item?._bundleEditUrl
                          : item?.isMarketplaceItem
                          ? item?.slug
                          : item?.slug) || '#'
                      }
                    >
                      <img
                        className="cursor-pointer"
                        src={item?.variant?.imageSet?.[FRONT_SMALL]?.src?.[0]?.url || item?.variant?.images?.[0]}
                        alt="Item Variant"
                      />
                    </Link>
                  )}
                  <p>{item?.cartId}</p>
                </section>
                <section
                  className={`${
                    item?.isGiftLineItem ||
                    (item?.variant?.attributes?.customProductType &&
                      PRODUCT_TYPES_WITH_QUANTITY_RESTRICTION?.includes(
                        item?.variant?.attributes?.customProductType,
                      )) ||
                    (!item?.variant?.attributes?.isPickupInStore &&
                      !item?.variant?.attributes?.isShipToStore &&
                      !item?.variant?.attributes?.isShipToAddress &&
                      !(item?.variant?.attributes?.customProductType === DONATION_ITEMS))
                      ? 'hidden'
                      : 'block'
                  }`}
                >
                  {!item?.isMarketplaceItem ? (
                    <QuantityDropdown
                      updateQuantity={editItemQuantity}
                      lineItem={item}
                      itemQty={itemQty}
                      isChangeStore={false}
                      loggedIn={loggedIn}
                      account={account}
                      fetchDefaultPayment={fetchDefaultPayment}
                      item={item}
                      data={data}
                      reCalculatePayments={reCalculatePayments}
                    />
                  ) : (
                    <section className="text-left md:text-center">
                      <select
                        disabled={true}
                        className="select min-w-[70px] cursor-not-allowed rounded border border-gray-600 text-affes-sm text-gray-900 opacity-100"
                      >
                        <option>{item.count}</option>
                      </select>
                    </section>
                  )}
                </section>
              </section>

              {/* item details */}
              <section className=" ml-2 flex-col ">
                <section className="itemDetails order-1   ">
                  <h2 className="mb-2 text-sm">
                    {loggedIn &&
                      item?.isMarketplaceItem &&
                      marketPlaceItemDetailsJSX[MARKET_PLACE_ITEM_ATTRIBUTES.VENDOR_NAME]}
                    <Link
                      href={
                        (!loggedIn
                          ? item?._guestUrl
                          : isBundleItem
                          ? item?._bundleEditUrl
                          : item?.isMarketplaceItem
                          ? item?.slug
                          : item?.slug) || '#'
                      }
                    >
                      <a className="cursor-pointer font-semibold text-blue-900">
                        {item?.variant?.attributes?.isPreOrderable &&
                          `${formatMessage({ id: 'preorder', defaultMessage: 'Preorder' })}`}{' '}
                        {item?.name}
                      </a>
                    </Link>
                  </h2>
                  <p className=" mb-2 text-sm font-medium">
                    {formatMessage({ id: 'item', defaultMessage: 'Item #:' })}{' '}
                    <span>{item?.isMarketplaceItem ? item?.clientLineSKUId : item?.variant?.sku}</span>
                  </p>
                  {/* marketplace supplier id */}
                  {loggedIn && item?.isMarketplaceItem && (
                    <>
                      {marketPlaceItemDetailsJSX[MARKET_PLACE_ITEM_ATTRIBUTES.ORDER_ID]}
                      {marketPlaceItemDetailsJSX[MARKET_PLACE_ITEM_ATTRIBUTES.SPECIFICATION]}
                    </>
                  )}
                  {isPreOrderable(item) && (
                    <PreOrder preOrderStreetDate={item?.variant?.attributes?.preOrderStreetDate} pageId="cart" />
                  )}
                  {bundleItems?.length > 0 &&
                    bundleItems?.map((bundleItem, i) => (
                      <section key={i}>
                        <p className="mb-2 break-all text-sm font-medium leading-5">
                          {bundleItem?.displayName} : {bundleItem?.productName}
                        </p>
                        {bundleItem?.personalizationLines?.length > 0 &&
                          bundleItem?.personalizationLines?.map((personalizationLine, j) => (
                            <p className="mb-2 break-all text-sm font-medium leading-5" key={j}>
                              {formatMessage({ id: 'personalization', defaultMessage: 'Personalization' })} :
                              {personalizationLine}
                            </p>
                          ))}
                      </section>
                    ))}
                  {item?.personalizationTextLines?.length > 0 && (
                    <p className="mb-2 inline-flex text-sm font-medium">
                      <span className="mr-2">
                        {formatMessage({ id: 'personalization', defaultMessage: 'Personalization' })}:
                      </span>
                      <ul className="break-all">
                        {item?.personalizationTextLines.map((personalizedLine, idx) => (
                          <li key={idx}>{personalizedLine}</li>
                        ))}
                      </ul>
                    </p>
                  )}
                  {item &&
                    !item?.isMarketplaceItem &&
                    !isGiftCards &&
                    item?.variant?.picker &&
                    renderVariantPickers(item)}
                </section>
                {shippingSurcharge && (
                  <>
                    <div className="my-2 flex">
                      <span className=" text-sm font-medium">
                        {' '}
                        {formatMessage({ id: 'Oversized.shipping', defaultMessage: 'Oversize Fee' })}:{' '}
                      </span>
                      <Price
                        className="ml-1 text-affes-base font-bold text-gray-900"
                        price={shippingSurcharge?.totalPrice}
                      />
                    </div>
                  </>
                )}
                {activationFeeItem && (
                  <>
                    <div className="flex items-end">
                      <p className="text-sm font-medium">{activationFeeItem?.name}: &nbsp;</p>
                      <Price
                        price={activationFeeItem?.totalPrice}
                        className="text-affes-base font-bold text-gray-900"
                      />
                    </div>
                  </>
                )}
                <section className="xl:hidden ">
                  <section className="w-52 text-left xl:text-right">
                    <ItemPrice item={item} view="cartMobile"></ItemPrice>
                  </section>
                </section>

                <section
                  className={`order-3 mt-2 flex text-sm ${
                    (item?.isGiftLineItem && !itemStockAvailability) || !item?.isGiftLineItem ? 'block' : 'hidden'
                  }`}
                >
                  <section className="relative">
                    <button
                      className=" mr-2 font-medium text-blue-900"
                      ref={btnRef}
                      onClick={() => {
                        setShowRemove(true);
                      }}
                    >
                      {mapLocaleToMeaningfulFormat(router.locale).remove}
                    </button>

                    {showRemove && (
                      <section className="absolute left-[-40px] top-[-100px] w-40 rounded-sm border border-gray-600 bg-white p-3 text-center">
                        <section className="boxArrow absolute left-14 -bottom-4 h-0 w-0 rotate-180 border-x-[16px] border-b-[16px] border-x-transparent border-b-gray-600 bg-transparent outline-gray-600">
                          <section className="triangle absolute left-[-16px] top-[1px] h-0 w-0 border-x-[16px] border-b-[16px] border-x-transparent border-b-white bg-transparent"></section>
                        </section>
                        <p className="mb-2 font-bold text-gray-900">
                          {formatMessage({ id: 'removeItem', defaultMessage: 'Remove Item?' })}
                        </p>
                        {!item?.isAttachment ? (
                          <button
                            type="button"
                            onClick={async () => {
                              await handleRemoveClick(item);
                              SmoothScroller.scrollToTop();
                            }}
                            className="mr-2 rounded-sm border border-blue-900 bg-white py-1 px-4 font-bold text-blue-900 hover:bg-blue-900 hover:text-white"
                          >
                            {formatCommonMessage({ id: 'yes', defaultMessage: 'Yes' })}
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={() => removeAddonProducts(item)}
                            className="mr-2 rounded-sm border border-blue-900 bg-white py-1 px-4 font-bold text-blue-900 hover:bg-blue-900 hover:text-white"
                          >
                            {formatCommonMessage({ id: 'yes', defaultMessage: 'Yes' })}
                          </button>
                        )}
                        <button
                          type="button"
                          className="rounded-sm border border-blue-900 bg-blue-900 py-1 px-4 font-bold text-white hover:bg-white hover:text-blue-900"
                          onClick={() => setShowRemove(false)}
                        >
                          {formatCommonMessage({ id: 'no', defaultMessage: 'No' })}
                        </button>
                      </section>
                    )}
                  </section>{' '}
                  {loggedIn && !isBundleItem && (
                    <>
                      {!item?.isMarketplaceItem && !item?.isGiftLineItem && (
                        <>
                          |{' '}
                          <button className=" ml-2 font-medium text-blue-900" onClick={() => moveSaveForLater(item)}>
                            {mapLocaleToMeaningfulFormat(router.locale).saveForLater}
                          </button>{' '}
                        </>
                      )}
                      {showModal && <Dialog closeModal={setShowModal} />}{' '}
                    </>
                  )}
                  {loggedIn && isBundleItem && (
                    <>
                      |{' '}
                      <button className=" ml-2 font-medium text-blue-900" onClick={handleBundleEditClick}>
                        {formatMessage({ id: 'edit.ub', defaultMessage: 'Edit' })}
                      </button>
                    </>
                  )}
                </section>
                {loggedIn && (
                  <>
                    {' '}
                    <section className="hidden xl:block">
                      {!item?.isMarketplaceItem &&
                        item?.variant?.attributes?.isSubscribable !== undefined &&
                        item?.variant?.attributes?.isSubscribable &&
                        !item?.isPickUp &&
                        item?.variant?.attributes?.productFrequences?.length && (
                          <SubscriptionServices
                            lineItem={item}
                            getAllCustomMessages={getAllCustomMessages}
                            showEditSubcriptionIcon={true}
                          />
                        )}
                    </section>
                    <section className="hidden xl:block">
                      {!item?.isMarketplaceItem &&
                        (item?.additionalLineItemIds?.length > 0 ||
                          item?.variant?.attributes?.referencedProducts_Cart?.length > 0) &&
                        !isBundleItem && (
                          <AdditionalServices
                            lineItem={item}
                            cartListItems={cartListItems}
                            setAddonItemId={setAddonItemId}
                            lineItemsStock={lineItemsStock}
                          />
                        )}
                    </section>
                  </>
                )}
              </section>
            </section>
            <section className="hidden xl:block ">
              <section className="flex  ">
                <section className="w-[15rem]">
                  {isDesktop && width && (
                    <ShippingOptions
                      shippingOption={index}
                      itemShippingAddresses={itemShippingAddresses}
                      data={item}
                      shippingAddresses={shippingAddresses}
                      giftCardImg={giftCardImg}
                      giftCardMessage={giftCardMessage}
                      itemQty={itemQty}
                      getAllCustomMessages={getAllCustomMessages}
                      currentItemStock={itemStockAvailability}
                      setShowRecipientDetailsErrMsg={setShowRecipientDetailsErrMsg}
                      overSizeFeeDetails={shippingSurcharge}
                      defaultShippingAddressNotAvailable={defaultShippingAddressNotAvailable}
                    />
                  )}
                </section>

                <section className="flex w-52 flex-col justify-between text-left xl:text-right">
                  <ItemPrice item={item} view="cartDesktop" />
                  {item?.productCategories?.map((category) => {
                    return (
                      category === projectConfiguration?.outletCategoryName && (
                        <section className="mb-1 flex items-center text-sm font-bold text-gray-900 md:border-none">
                          {getAllCustomMessages?.outletFinalSaleNoReturnPromo}
                        </section>
                      )
                    );
                  })}
                </section>
              </section>
            </section>
          </section>

          <section className="">
            {item?.productCategories?.map((category) => {
              return (
                category === projectConfiguration?.outletCategoryName && (
                  <section className="block xl:hidden">
                    <section className="mb-1 flex items-center text-sm font-bold text-gray-900 md:border-none">
                      {getAllCustomMessages?.outletFinalSaleNoReturnPromo}
                    </section>
                  </section>
                )
              );
            })}
            {!item?.isMarketplaceItem && (
              <section className="block py-3 xl:hidden">
                {item?.variant?.attributes?.isSubscribable !== undefined &&
                  item?.variant?.attributes?.isSubscribable &&
                  !item?.isPickUp &&
                  !item?.isPickUp &&
                  item?.variant?.attributes?.productFrequences?.length && (
                    <SubscriptionServices
                      lineItem={item}
                      getAllCustomMessages={getAllCustomMessages}
                      showEditSubcriptionIcon={true}
                    />
                  )}
              </section>
            )}
            {!item?.isMarketplaceItem && (
              <section className="block py-3 xl:hidden ">
                {(item?.additionalLineItemIds?.length > 0 ||
                  item?.variant?.attributes?.referencedProducts_Cart?.length > 0) &&
                  !isBundleItem && (
                    <AdditionalServices
                      lineItem={item}
                      cartListItems={cartListItems}
                      setAddonItemId={setAddonItemId}
                      lineItemsStock={lineItemsStock}
                    />
                  )}
              </section>
            )}
            <section className="w-full">
              {!item?.isMarketplaceItem && item?.discountName && <IncludedFree offer={item?.discountName} />}
              {!item?.isMarketplaceItem && item?.variant?.attributes?.ageRestriction && (
                <section>
                  <section className="border-[rgba(153, 153, 153, 0.3)] mt-3 rounded border px-2">
                    <AgeRestricted className="text-[#DA0F0F]" />
                  </section>
                </section>
              )}
              {item?.discounts?.length > 0 && (
                <section className="border-gray min-h-[46px] items-center rounded border px-[15px] py-3">
                  {itemDiscounts?.map((discountText, index) => (
                    <div className="flex" key={index}>
                      <AiOutlineTag className="my-auto mx-1 text-gray-900" /> <p>{discountText.name}</p>
                    </div>
                  ))}
                </section>
              )}

              {item?.isMarketplaceItem && marketPlaceItemDetailsJSX[MARKET_PLACE_ITEM_ATTRIBUTES.VENDOR_NAME_COUPON]}

              {!item?.isMarketplaceItem && item?.variant?.isGiftMessageEligible && (
                <GiftBox keyVal={index} lineItem={item} showEditIcon={true} />
              )}
            </section>
          </section>
          <section className="block py-2 xl:hidden">
            <ShippingOptions
              data={item}
              itemShippingAddresses={itemShippingAddresses}
              shippingOption={index}
              shippingAddresses={shippingAddresses}
              getAllCustomMessages={getAllCustomMessages}
              currentItemStock={itemStockAvailability}
              setShowRecipientDetailsErrMsg={setShowRecipientDetailsErrMsg}
              overSizeFeeDetails={shippingSurcharge}
              defaultShippingAddressNotAvailable={defaultShippingAddressNotAvailable}
            />
          </section>
        </section>
      </section>
    </>
  );
};

export default CartItem;
