import { useEffect, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Loader from 'components/commercetools-ui/loader/Loader';
import { UIProduct } from 'components/commercetools-ui/products/product-details';
import LinkIcon from 'components/icons/link';
import { NETWORKS, GTM_SHARE, GTM_MARKET_PLACE, GTM_MARKETPLACE_SITES } from 'helpers/constants/aafes';
import useGATrackViewActions from 'helpers/hooks/gaEvents/useGATrackViewActions';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { sendToMonetate } from 'helpers/utils/monetateHelper';
import { useAccount, useCart, useProduct } from 'frontastic';
const LoginModal = dynamic(() => import('../../Modals/Login/login'));
import ApplicablePromotions from '../../pdp/product-preview/applicablePromotions';
import { InlineShareButtonsMP } from '../../sharethis';
import Reviews from '../product-details/Reviews';
import Breadcrumb from '../product-preview/breadcrumb';
import ImageGallery from '../product-preview/ImageGallery';
import { tablet } from 'helpers/utils/screensizes';
import RatingStars from '../product-preview/RatingStars';

type Props = {
  product: UIProduct;
};

const MarketplaceProduct = ({ product, pdpDroplets }) => {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const productSku = product?.variants?.[0]?.sku;
  const variant = product?.variants?.[0];
  const { marketPlaceDetails } = useProduct();
  const { loggedIn, account } = useAccount();
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [marketPlaceData, setMarketPlaceData] = useState<Record<string, any>>({});
  const [loginModal, setLoginModal] = useState(false);
  const [fetchingMPData, setFetchingMPData] = useState<boolean>(false);
  const { studioConfig, setCartProductDetailEventSent, data } = useCart();
  const [isTablet] = useMediaQuery(tablet);
  const hasPromotions = pdpDroplets && pdpDroplets.length > 0 && pdpDroplets[0]?.promotions;
  const vendorAutomaticRedirect = product?.variants[0]?.attributes?.vendorAutomaticRedirect;
  const loggedInRef = useRef(loggedIn);
  useEffect(() => {
    loggedInRef.current = loggedIn;
  }, [loggedIn]);
  const isDisableShopNow = () => {
    if (
      product?.variants?.[0]?.availability?.totalQuantity === 0 ||
      !product?.marketplaceRequestUrl ||
      product?.marketplaceRequestUrl === null
    )
      return true;
    if (loggedIn || loggedInRef.current) {
      if (!fetchingMPData && !marketPlaceData?.aafes_auth) return true;
    }
    return false;
  };
  const router = useRouter();
  const copyUrlToClipboard = () => {
    const url = window?.location?.href;
    navigator?.clipboard?.writeText(url);
  };

  function closeLoginModal() {
    if (vendorAutomaticRedirect) {
      router.push('/');
    }
    setLoginModal(false);
  }

  const getmarketPlaceDetails = async (sku) => {
    setIsLoad(true);
    setFetchingMPData(true);
    try {
      const fetchedData = await marketPlaceDetails(sku);
      setMarketPlaceData(fetchedData);
      if (!loggedInRef.current && vendorAutomaticRedirect) {
        setLoginModal(true);
      } else if (
        loggedInRef.current &&
        vendorAutomaticRedirect &&
        product?.marketplaceRequestUrl &&
        product?.marketplaceRequestUrl !== null &&
        fetchedData?.aafes_auth
      ) {
        await handleClickShopNow(product?.marketplaceRequestUrl, fetchedData);
      } else if (
        vendorAutomaticRedirect &&
        loggedInRef.current &&
        (!product?.marketplaceRequestUrl || product?.marketplaceRequestUrl === null || !fetchedData?.aafes_auth)
      ) {
        router.push('/');
      }
    } catch (error) {
    } finally {
      if (!vendorAutomaticRedirect) {
        setIsLoad(false);
      }
      setFetchingMPData(false);
    }
  };

  const handleClickShopNow = (URL, fetchedData?) => {
    const marketPlaceFetchedData = fetchedData || marketPlaceData;
    if (loggedIn || loggedInRef.current) {
      setIsLoad(true);
      const form = document.createElement('form');
      form.method = variant?.attributes?.integratedCart ? 'POST' : 'GET';
      form.action = URL;
      form.enctype = 'application/x-www-form-urlencoded';

      if (variant?.attributes?.integratedCart === false) {
        // Only pass 'aafes_auth' key
        if (marketPlaceFetchedData.hasOwnProperty('aafes_auth')) {
          const input = document.createElement('input');
          input.type = 'hidden';
          input.name = 'aafes_auth';
          input.value = marketPlaceFetchedData['aafes_auth'];
          form.appendChild(input);
        }
      } else {
        // Pass all keys
        for (const key in marketPlaceFetchedData) {
          if (marketPlaceFetchedData.hasOwnProperty(key)) {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = key;
            input.value = marketPlaceFetchedData[key];
            form.appendChild(input);
          }
        }
      }

      document.body.appendChild(form);
      form.submit();
    } else {
      setLoginModal(true);
    }
  };

  const [monetateConfig, setMonetateConfig] = useState(null);
  const [monetateSent, setMonetateSent] = useState(false);

  useEffect(() => {
    loggedIn && !marketPlaceData?.aafes_auth && getmarketPlaceDetails(productSku);
  }, [productSku, loggedIn]);

  useEffect(() => {
    if (vendorAutomaticRedirect) {
      getmarketPlaceDetails(productSku);
    }
  }, [vendorAutomaticRedirect, loggedInRef.current]);

  useEffect(() => {
    if (studioConfig?.monetateConstants) {
      try {
        const monetateConfig = JSON?.parse(studioConfig?.monetateConstants?.replace(/'/g, '"'));
        setMonetateConfig(monetateConfig);
      } catch (error) {
        console.error('Errorparsing projectConfig:', error);
      }
    }
  }, [studioConfig]);

  useEffect(() => {
    const processMonetate = async () => {
      const callMonetate = async () => {
        setMonetateSent(true);
        await sendToMonetate(
          {
            page: 'marketplace_pdp',
            cart: {
              ...data,
            },
          },
          monetateConfig,
        );
      };
      if (data && monetateConfig && !monetateSent) {
        await callMonetate();
        setCartProductDetailEventSent(true);
      }
    };
    const fetchData = async () => {
      await processMonetate();
    };
    fetchData();
  }, [data, monetateConfig]);

  return (
    <section>
      {isTablet && (
        <div className="my-4">
          <Breadcrumb categories={product?.categories} />
        </div>
      )}
      <section>
        {isLoad && <Loader />}
        {!vendorAutomaticRedirect && (
          <section>
            <section className="my-5 flex">
              <section className="flex flex-col gap-[10px] md:gap-[2px]">
                <h1 className="text-[21px] font-normal leading-[30px] text-gray-900 md:text-[24px]">{product?.name}</h1>
                <section>
                  <RatingStars productId={product?.productId} />
                </section>
              </section>
            </section>
            <section className="mb-6 grid grid-cols-1 gap-x-6 gap-y-2 md:grid-cols-2 xl:grid-cols-[minmax(0,47%)_1fr] xl:gap-y-[3%]">
              <section className="bg-white-300 shrink-0">
                <ImageGallery product={product} />
              </section>
              <section>
                {isDisableShopNow() && (
                  <p style={{ color: 'red' }}>
                    {formatProductMessage({
                      id: 'marketplaceStoreIssueMessage',
                      defaultMessage:
                        'We are experiencing technical issues with this Marketplace store. Please check again later.',
                    })}
                  </p>
                )}
                {hasPromotions && (
                  <ApplicablePromotions
                    variantPromotion={pdpDroplets[0]?.promotions}
                    studioConfig={studioConfig}
                    isMarketplaceProduct={product?.isMarketplaceProduct}
                  />
                )}

                <section className="max-w-full md:w-[213px]">
                  {/* ToDo -- Static Content Here */}
                  <button
                    onClick={() => {
                      const enableStageHomeDepot = studioConfig?.ENABLE_STAGE_HOME_DEPOT;
                      console.info('enableStageHomeDepot flag===', studioConfig?.ENABLE_STAGE_HOME_DEPOT);
                      if (enableStageHomeDepot) {
                        const stageHomeDepotUrl = studioConfig?.STAGE_HOME_DEPOT_REQUEST_URL;
                        console.info('stageHomeDepotUrl===', studioConfig?.STAGE_HOME_DEPOT_REQUEST_URL);
                        if (null != stageHomeDepotUrl) {
                          console.info('In if block & stageHomeDepotUrl===', stageHomeDepotUrl);
                          handleClickShopNow(stageHomeDepotUrl);
                        } else {
                          console.info('In 1 else block & marketplaceRequestUrl===', product?.marketplaceRequestUrl);
                          handleClickShopNow(product?.marketplaceRequestUrl);
                        }
                      } else {
                        console.info('In 2 else block & marketplaceRequestUrl===', product?.marketplaceRequestUrl);
                        handleClickShopNow(product?.marketplaceRequestUrl);
                      }
                    }}
                    disabled={isDisableShopNow()}
                    className={`font-lato flex h-11 w-full items-center ${
                      isDisableShopNow() ? 'bg-gray-400' : 'bg-blue-900'
                    } justify-center rounded-[4px] py-[6px] px-5 text-center text-base font-[700] leading-[19px] text-white`}
                    data-gtm-exit
                    data-gtm-id={GTM_MARKETPLACE_SITES}
                  >
                    {formatProductMessage({ id: 'shop.now', defaultMessage: 'Shop Now' })}
                  </button>
                  <section className="my-2 grid grid-cols-4 gap-x-2.5">
                    <div className="col-span-3">
                      <InlineShareButtonsMP
                        config={{
                          alignment: 'center', // alignment of buttons (left, center, right)
                          color: 'grey', // set the color of buttons (social, white)
                          enabled: true, // show/hide buttons (true, false)
                          labels: null, // button labels (cta, counts, null)
                          language: 'en', // which language to use (see LANGUAGES)
                          networks: NETWORKS,
                          padding: 0,
                          marketplaceId: product?.name,
                        }}
                      />
                    </div>
                    <div className="relative flex items-center justify-center">
                      <div
                        className="icon ease cursor-pointer transition-transform duration-200 hover:translate-y-[-5px] hover:opacity-80"
                        onClick={copyUrlToClipboard}
                        data-gtm-share
                        data-gtm-method={GTM_SHARE}
                        data-gtm-type={GTM_MARKET_PLACE}
                        data-gtm-id={product?.productId}
                      >
                        <LinkIcon />
                      </div>
                    </div>
                  </section>
                </section>
                <section
                  className="font-lato mb-4 text-sm leading-5 text-[#222222]"
                  dangerouslySetInnerHTML={{ __html: product?.description }}
                ></section>
              </section>
            </section>
            <section className="mt-[50px]">
              <Reviews />
            </section>
          </section>
        )}
      </section>
      <LoginModal
        loginModal={loginModal}
        closeModal={closeLoginModal}
        closeAccountFlyout={closeLoginModal}
        loginCallBack={() => router.push(router.asPath)}
      />
    </section>
  );
};

export default MarketplaceProduct;
